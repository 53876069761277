import React, { FC } from "react";
import { OPEN_SIDE_MENU, SIDE_MENU_FRAGMENT } from "../../../constant";

export type PopularListProps = {
  title: string;
  elements?: { path: string; displayName: string }[];
  index?: number;
  buttonText: string;
};

const PopularList: FC<PopularListProps> = ({
  title = "",
  elements = [],
  index,
  buttonText,
}: PopularListProps): JSX.Element | null => {
  const handleClick = async () => {
    if ((window as any).PubSub) {
      if (typeof (window as any).PuzzleJs !== "undefined") {
        await (window as any).PuzzleJs.Core.renderAsyncFragment(SIDE_MENU_FRAGMENT);
      }

      (window as any).PubSub.publish(OPEN_SIDE_MENU);
    }
  };

  if (Array.isArray(elements) && elements.length > 0) {
    return (
      <section>
        <div className="popular-list-title">
          <strong>{title}</strong>
          {index === 1 && (
            <label data-testid="see-all" className="see-all" onClick={handleClick}>
              {buttonText}
            </label>
          )}
        </div>
        <ul>
          {elements.map((el: { path: string; displayName: string }) => (
            <li key={el.path}>
              <a href={el.path} title={el.displayName}>
                {el.displayName}
              </a>
            </li>
          ))}
        </ul>
      </section>
    );
  }
  return null;
};

export default PopularList;
