/* istanbul ignore file */
import "./styles/style.scss";
import ReactDOM from "react-dom";
import * as React from "react";
import { FRAGMENT_NAME } from "../constant";
import Footer from "./components/footer/footer";
import { getValueWithEmptyCheck } from "../../../../helpers/emptyCheck";

const hasError = getValueWithEmptyCheck(() => window[`__${FRAGMENT_NAME}__PROPS`].hasError, false);

if ((window as any).notFound || hasError) {
  ReactDOM.hydrate(<div data-dr-hide="true" />, document.querySelector(`#${FRAGMENT_NAME}`));
} else {
  if (window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]) {
    ReactDOM.hydrate(
      <Footer {...window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]} />,
      document.getElementById(FRAGMENT_NAME)
    );
  }
}

// for debug on preprod
if ((window as any).dispatchDREvent) {
  (window as any).dispatchDREvent({ key: "internal-linking", type: "done" });
}
