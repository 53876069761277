import React, { FC } from "react";
import PopularList from "./popular-list";

export type FooterProps = {
  data?: Array<{ title: string; elements: { path: string; displayName: string }[] }>;
  buttonText: string;
};
const Footer: FC<FooterProps> = ({
  data = [{ title: "", elements: [] }],
  buttonText = "Tümü",
}: FooterProps): JSX.Element | null => {
  if (Array.isArray(data) && data.length > 0) {
    return (
      <div id="footer-fragment-context" data-drroot="internal-linking">
        <div data-testid="internal-links-footer">
          {data.map((row: any, index) => (
            <PopularList
              title={row.title}
              elements={row.elements}
              key={row.title + index}
              index={index}
              buttonText={buttonText}
            />
          ))}
        </div>
      </div>
    );
  }
  return <div data-drroot="internal-linking"></div>;
};

export default Footer;
